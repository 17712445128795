export default [
  {
    header: 'Home',
  },
  {
    title: 'Dashboard',
    route: 'client-dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Transaction',
  },
  {
    title: 'Exchange',
    route: 'client-exchange',
    icon: 'DollarSignIcon',
  },
  {
    title: 'History',
    route: 'client-history',
    icon: 'AlignJustifyIcon',
  },
  {
    header: 'Help',
  },
  {
    title: 'Support',
    route: 'client-support',
    icon: 'MailIcon',
  },
  // {
  //   title: 'Faq',
  //   route: 'client-faq',
  //   icon: 'AlertOctagonIcon',
  // },
  {
    title: 'Feedback',
    route: 'client-feedback',
    icon: 'StarIcon',
  },
  {
    header: 'KYC',
  },
  {
    title: 'Profile',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'KYC-SUBMIT',
        route: 'client-kyc-submit',
      },
      {
        title: 'KYC-LIST',
        route: 'client-kyc-listing',
      },
    ],
  },
]
