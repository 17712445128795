<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="data.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title text-info mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ data.length }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="data.length > 0"
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in data"
        :key="notification.id"
      >
        <b-media>
          <p class="media-heading">
            <span class="font-weight-lighter">
              {{ notification.information }}
            </span>
          </p>
          <small class="mt-2 whitespace-no-wrap font-weight-bold text-danger">{{ elapsedTime(notification.updated_at) }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
    <p
      v-else
      class="p-2"
    >
      Aucune notification.
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  methods: {
    elapsedTime(startTime) {
      const x = new Date(startTime)
      const now = new Date()
      let timeDiff = now - x
      timeDiff /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      const hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      const days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      const years = timeDiff

      if (years > 0) {
        return `Il y a ${years + (years > 1 ? ' années ' : ' année ')}`
      } if (days > 0) {
        return `Il y a ${days + (days > 1 ? ' jrs ' : ' jr ')}`
      } if (hours > 0) {
        return `Il y a ${hours + (hours > 1 ? ' hrs ' : ' hr ')}`
      } if (minutes > 0) {
        return `Il y a ${minutes + (minutes > 1 ? ' mins ' : ' Min ')}`
      } if (seconds > 0) {
        return seconds + (seconds > 1 ? ' s' : 'juste maintenant')
      }

      return 'Just Now'
    },
  },
}
</script>

<style>

</style>
