export default [
  {
    header: 'Home',
  },
  {
    title: 'Dashboard',
    route: 'admin-dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Transaction',
  },
  {
    title: 'Exchange',
    route: 'admin-exchange',
    icon: 'DollarSignIcon',
  },
  {
    title: 'History',
    route: 'admin-history',
    icon: 'AlignJustifyIcon',
  },
  {
    header: 'Help',
  },
  {
    title: 'Support',
    route: 'admin-support',
    icon: 'MailIcon',
  },
  {
    title: 'Faq',
    route: 'admin-faq',
    icon: 'AlertOctagonIcon',
  },
  // {
  //   title: 'Feedback',
  //   route: 'admin-feedback',
  //   icon: 'StarIcon',
  // },
  {
    header: 'KYC',
  },
  {
    title: 'Profile',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'KYC-SUBMIT',
        route: 'admin-kyc-submit',
      },
      {
        title: 'KYC-LIST',
        route: 'admin-kyc-listing',
      },
    ],
  },
  // {
  //   header: 'KYC',
  // },
  // {
  //   title: 'Profile Validation',
  //   icon: 'UserCheckIcon',
  //   children: [
  //     {
  //       title: 'Pending Profile',
  //       route: 'admin-profile-pending-validation',
  //     },
  //     {
  //       title: 'Valid Profile',
  //       route: 'admin-profile-valids',
  //     },
  //   ],
  // },

  {
    header: 'Settings-Header',
  },
  {
    title: 'Settings',
    route: 'admin-settings',
    icon: 'SettingsIcon',
  },

]
