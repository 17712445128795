<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <div class="bookmark-wrapper flex-grow-1">
        <dark-toggler />
      </div>
      <div class="bookmark-wrapper flex-grow-1">
        <notification-dropdown :data="notifications" />
      </div>

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ currentUser.name }} {{ currentUser.surname }}
            </p>
            <span class="user-status">{{ currentUser.roles.toLowerCase() === 'xjzt' ? 'admin' : currentUser.roles.toLowerCase() }}</span>
          </div>
          <b-avatar
            size="40"
            variant="primary"
            badge
            :text="avatarName"
            class="badge-minimal"
            :badge-variant="getStatus"
          />
        </template>

        <b-dropdown-item
          :to="getLink"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('Profile') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import { logoutUser, getPrivileges } from '@/auth/utils'
import { mapGetters } from 'vuex'

export default {
  components: {
    // Navbar Components
    DarkToggler,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      notifications: [],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    avatarName() {
      return (
        `${this.currentUser.name.charAt(0).toUpperCase()}${this.currentUser.surname.charAt(0).toUpperCase()}`
      )
    },
    getLink() {
      let value = null
      if (getPrivileges() === 'admin') {
        value = { name: 'admin-account-settings' }
      } else {
        value = { name: 'account-settings' }
      }
      return value
    },
    getStatus() {
      let status = null
      if (this.currentUser.verification_state === 'VALID') {
        status = 'success'
      } else if (this.currentUser.verification_state === 'NEW_DOCUMENT') {
        status = 'info'
      } else if (this.currentUser.verification_state === 'WAITING') {
        status = 'warning'
      } else if (this.currentUser.verification_state === 'PENDING') {
        status = 'warning'
      } else {
        status = 'danger'
      }
      return status
    },
  },
  mounted() {
    this.getNotifications()
  },
  methods: {
    logout() {
      logoutUser()
    },
    async getNotifications() {
      this.notifications = await this.$http
        .get('/information/all-state-and-type/1/NOTIFICATION/5',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => res.data.data)
        .catch(() => [])
    },
  },
}
</script>
